import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import shortUUID from 'short-uuid';

import { getDateTimeData, defaultDateTimeData } from 'utils/timestamp-utils';
import countries from './countries.messages';

// https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const accessCodeUUID = shortUUID('123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ!()_');

const messages = defineMessages({
    virtualClassLabel: {
        id: 'classForm.virtualLabel',
        defaultMessage: 'Virtual classroom',
    },
    physicalClassLabel: {
        id: 'classForm.physicalClassLabel',
        defaultMessage: 'In-person classroom',
    },
    newestCourseVersion: {
        id: 'classForm.newestCourseVersion',
        defaultMessage: 'newest',
    },
});

export const LOCATION_TYPES = {
    physical: 'physical',
    virtual: 'virtual',
};

export const MAX_CLASS_CAPACITY = 300;

/**
 * Helper method to get formatted react-intl message for class type
 * Will be either `In-Person Classroom` or `Virtual Classroom`
 *
 * @param {String} type LOCATION_TYPE, should be either physical or virtual
 * @return Class location label for physical or virtual classroom, or undefined
 */
export const useLocationTypeLabel = type => {
    const { formatMessage } = useIntl();

    if (type === LOCATION_TYPES.physical) {
        return formatMessage(messages.physicalClassLabel);
    }
    if (type === LOCATION_TYPES.virtual) {
        return formatMessage(messages.virtualClassLabel);
    }
};

export const handleFormValueChange = ({ value, setData, keyPath }) =>
    setData(prevState => ({
        ...prevState,
        [keyPath]: value,
    }));

export const mapCoursesToSelectObject = ({ courseId, title }) => ({
    id: courseId,
    label: title,
});

export const mapCoursesVersionsToSelectObject = formatMessage => (
    { courseId, versionId },
    index
) => ({
    id: courseId,
    label:
        index === 0 ? `${versionId} (${formatMessage(messages.newestCourseVersion)})` : versionId,
});

export const mapLocalesToSelectObject = locale => ({
    id: locale,
    label: locale,
});

export const prepareCountriesForSelect = formatMessage => {
    return Object.keys(countries)
        .reduce((acc, cur) => {
            return [...acc, { id: cur, label: formatMessage(countries[cur]) }];
        }, [])
        .sort((a, b) => {
            const [labelA, labelB] = [a.label, b.label];
            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
        });
};

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const transformIncomingDataToFormData = data => {
    if (!data) return {};
    const { courseId, langLocale, instructors = [], locationData = {}, classCapacity = 0 } = data;
    const {
        locationType = LOCATION_TYPES.virtual,
        physicalAddress,
        virtualUrl,
        timezone,
    } = locationData;
    const { addressLine1, addressLine2, city, state, postalCode, country } = physicalAddress || {};

    return {
        langLocale,
        locationType,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
        country,
        virtualUrl,
        timezone,
        instructors,
        courseId: courseId
            .match(/(arn:aws:learningcontent:[^:]+:\d+:collectionversion\/[^:]+):/)[1]
            .replace('version', ''),
        courseVersionId: courseId,
        classCapacity: `${classCapacity}`,
    };
};

export const transformIncomingDataToDateTimeData = data => {
    if (!data) return defaultDateTimeData;
    const { locationData = {}, startsOn, endsOn } = data;
    const { timezone } = locationData;
    return getDateTimeData({ startsOn, endsOn, timezone });
};

export const putUserEmailFirstInArray = (instructors, userEmail) => {
    const userIndex = instructors.findIndex(email => email === userEmail);
    if (userIndex === -1) return instructors;

    const copy = [...instructors];
    copy.splice(userIndex, 1);
    copy.unshift(userEmail);
    return copy;
};

const filterEmptiesFromArray = arr => {
    if (!arr || !Array.isArray(arr)) return undefined;
    return arr.filter(i => i.trim());
};

export const prepareFormData = ({
    timezone,
    courseVersionId: courseId,
    classCapacity,
    accessCode: incomingAccessCode,
    langLocale,
    virtualUrl,
    locationType,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    startsOn,
    endsOn,
    instructors,
    providerArn,
    classroomId,
}) => {
    let mutationVars = {};

    const locationData =
        locationType === LOCATION_TYPES.physical
            ? {
                  physicalAddress: {
                      addressLine1,
                      ...(addressLine2 && { addressLine2 }),
                      city,
                      state,
                      postalCode,
                      country,
                  },
              }
            : { virtualUrl };
    const sanitizedInstructors = filterEmptiesFromArray(instructors);

    mutationVars = {
        clientRequestToken: shortUUID.generate(),
        instructors: sanitizedInstructors,
        classroom: {
            startsOn,
            endsOn,
            courseId,
            providerArn,
            langLocale,
            locationData: {
                ...locationData,
                timezone,
                locationType,
            },
        },
    };

    if (!classroomId) {
        // params specific to new classes
        mutationVars.accessCode = accessCodeUUID.generate();
        mutationVars.classCapacity = parseInt(classCapacity);
    } else {
        mutationVars.classroomId = classroomId;
        if (incomingAccessCode && parseInt(classCapacity)) {
            mutationVars.classCapacity = parseInt(classCapacity);
            mutationVars.accessCode = incomingAccessCode;
        }
    }

    return mutationVars;
};

export const validationErrorCodeMap = {
    required: 'Required',
    length: 'Length',
    numLimit: 'NumLimit',
    pattern: 'Pattern',
    type: 'Type',
    enum: 'Enum',
    invalidInclusion: 'InvalidInclusion',
    unauthorized: 'Unauthorized',
    decreaseInvalid: 'DecreaseInvalid',
};

export const formFields = [
    'courseId',
    'courseVersionId',
    'langLocale',
    'classCapacity',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'virtualUrl',
    'timezone',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postalCode',
    'country',
    'instructors',
];
const fieldsToValidate = formFields.filter(field => !['addressLine2'].includes(field));

export const initialFieldsInvalidState = fieldsToValidate.reduce(
    (acc, field) => ({ ...acc, [field]: false }),
    {}
);

const requiredField = val =>
    val === undefined || val.length === 0 ? [{ code: validationErrorCodeMap.required }] : false;

const fieldValidators = {
    courseId: requiredField,
    courseVersionId: requiredField,
    langLocale: requiredField,
    instructors: (val, { userInfo }) => {
        const errors = val.map(v => {
            if (!v) {
                return { code: validationErrorCodeMap.required, values: [v] };
            }
            if (!EMAIL_REGEX.test(v)) {
                return { code: validationErrorCodeMap.pattern, values: [v] };
            }
            if (userInfo?.userIsTrainingCoordinator && v === userInfo?.email) {
                return { code: validationErrorCodeMap.invalidInclusion, values: [v] };
            }
            return false;
        });
        return errors.some(v => v) ? errors : false;
    },
    classCapacity: (val, { rosterSizeDifference }) => {
        if (val < 1 || val > MAX_CLASS_CAPACITY) {
            return [{ code: validationErrorCodeMap.numLimit }];
        } else if (rosterSizeDifference < 0) {
            return [{ code: validationErrorCodeMap.decreaseInvalid }];
        } else {
            return false;
        }
    },
    startDate: requiredField,
    endDate: requiredField,
    startTime: requiredField,
    endTime: requiredField,
    timezone: requiredField,
    virtualUrl: (val, { locationType }) =>
        locationType === LOCATION_TYPES.virtual && requiredField(val),
    addressLine1: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    city: (val, { locationType }) => locationType === LOCATION_TYPES.physical && requiredField(val),
    state: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    postalCode: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    country: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
};

export const validateData = (data, userInfo = {}, rosterSizeDifference) => {
    // loop through fields to check if data is valid
    const dataValidity = fieldsToValidate.reduce((acc, field) => {
        return {
            ...acc,
            [field]: fieldValidators[field](data[field], {
                locationType: data.locationType,
                userInfo,
                rosterSizeDifference,
            }),
        };
    }, {});

    return { dataValidity, allValid: Object.values(dataValidity).every(val => !val) };
};

// extra validator that makes sure all checks are not true
export const allChecksPass = (...values) => values.every(val => !val);

// given a form property that has multiple inputs (such as the list of instructors)
// this will find all the errors associated with the specific value to be able
// to display the errors alongside the field
export const aggregatePropertyErrors = (value, errors) => {
    if (!errors) return false;
    const aggregate = errors.filter(({ values }) => values.includes(value));
    return !aggregate.length ? false : aggregate;
};
