import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import {
    ClassPage,
    ClassDetailPage,
    ClassEditPage,
    ClassCreatePage,
    ErrorPage,
    LabPage,
    ProvidersPage,
    ClassListPage,
} from 'containers';
import { Authenticator, Header, Footer, ErrorFallback } from 'components';
import { ProviderCtxProvider } from 'data/ProviderContext';
import { paths, parameters, redirect } from 'utils/paths';
import { UserInfoProvider } from 'utils/userInfo';
import { useFlags } from 'utils/flags';
import { QueryClientProviderWrapper } from 'utils/QueryClientProvider';
import './App.css';

const { classroomId } = parameters;

const App = ({ globals = window }) => {
    const features = useFlags();
    return (
        <div className="awsui">
            <Authenticator globals={globals}>
                <QueryClientProviderWrapper>
                    <ProviderCtxProvider globals={globals}>
                        <UserInfoProvider>
                            <Header />
                            <main className="app-body">
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <Switch>
                                        <Route
                                            path="/callback"
                                            exact
                                            render={() => (
                                                <Redirect to={redirect(globals, features)} />
                                            )}
                                        />
                                        <Route
                                            path="/"
                                            exact
                                            render={() => (
                                                <Redirect to={redirect(globals, features)} />
                                            )}
                                        />
                                        <Route
                                            exact
                                            strict
                                            path="(.*//+.*)"
                                            render={({ location }) => (
                                                <Redirect
                                                    to={location.pathname.replace(/\/\/+/g, '/')}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={paths.providersPage}
                                            component={ProvidersPage}
                                        />
                                        {features.showClassList ? (
                                            <Route
                                                exact
                                                path={paths.classListPage}
                                                component={ClassListPage}
                                            />
                                        ) : null}
                                        <Route
                                            exact
                                            path={paths.classPage('new')}
                                            component={ClassCreatePage}
                                        />
                                        <Route
                                            exact
                                            path={paths.classPage(classroomId)}
                                            component={ClassPage}
                                        />
                                        <Route
                                            exact
                                            path={paths.classDetailPage(parameters.classroomId)}
                                            render={props => (
                                                <ClassDetailPage {...props} globals={globals} />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={paths.classEditPage(parameters.classroomId)}
                                            component={ClassEditPage}
                                        />
                                        <Route
                                            path={paths.labPage(
                                                parameters.classroomId,
                                                parameters.labId
                                            )}
                                            component={LabPage}
                                        />
                                        <Route component={ErrorPage} />
                                    </Switch>
                                </ErrorBoundary>
                            </main>
                            <Footer />
                        </UserInfoProvider>
                    </ProviderCtxProvider>
                </QueryClientProviderWrapper>
            </Authenticator>
        </div>
    );
};

export default App;
