import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormField, DatePicker, FormSection, TimeInput } from '@amzn/awsui-components-react';
import FormFieldV3 from '@amzn/awsui-components-react-v3/polaris/form-field';

import { TimeZoneSelect } from 'components';
import { messages } from '../ClassForm.messages';
import { handleFormValueChange, allChecksPass } from '../ClassForm.utils';
import { FieldErrors, ErrorMessage } from '../FieldErrors';
import {
    isInvalidDate,
    isInvalidTime,
    isInvalidDateDiff,
    isDateTimeInPast,
} from 'utils/timestamp-utils';
import '../ClassForm.css';

const ClassTime = ({
    fieldsInvalid,
    timezone,
    setClassData,
    dateTimeData,
    setDateTimeData,
    setDateTimesAreValid,
    hasClassStarted,
}) => {
    const { formatMessage, locale } = useIntl();

    const endDateInPast =
        !hasClassStarted && isDateTimeInPast(dateTimeData.endDate, dateTimeData.endTime, timezone);
    const endDateBeforeStartDate = isInvalidDate(dateTimeData.startDate, dateTimeData.endDate);
    // verify end date is within 5 weeks of start date
    const endDateTooFar = isInvalidDateDiff(dateTimeData.startDate, dateTimeData.endDate);
    const endTimeBeforeStartTime = isInvalidTime(dateTimeData);

    useEffect(() => {
        setDateTimesAreValid(
            allChecksPass(
                endDateInPast,
                endDateBeforeStartDate,
                endTimeBeforeStartTime,
                endDateTooFar
            )
        );
    }, [
        endDateInPast,
        endDateBeforeStartDate,
        endTimeBeforeStartTime,
        endDateTooFar,
        setDateTimesAreValid,
    ]);

    return (
        <FormSection header={formatMessage(messages.classTimeHeader)}>
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-12">
                        <FormFieldV3 stretch label={formatMessage(messages.timeZoneLabel)}>
                            <TimeZoneSelect
                                invalid={!!fieldsInvalid.timezone || endDateInPast}
                                disabled={hasClassStarted}
                                selected={timezone}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.selectedOption.id,
                                        setData: setClassData,
                                        keyPath: 'timezone',
                                    });
                                }}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="timezone" />
                        </FormFieldV3>
                    </div>
                </div>
                <div className="awsui-row">
                    <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                        <FormField stretch label={formatMessage(messages.startDateLabel)}>
                            <DatePicker
                                id="classForm__startDate"
                                ariaRequired
                                invalid={!!fieldsInvalid.startDate}
                                disabled={hasClassStarted}
                                locale={locale}
                                value={dateTimeData.startDate}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.value,
                                        setData: setDateTimeData,
                                        keyPath: 'startDate',
                                    });
                                }}
                                placeholder="YYYY/MM/DD"
                                todayLabel={formatMessage(messages.todayLabel)}
                                nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                previousMonthLabel={formatMessage(messages.previousMonthLabel)}
                            ></DatePicker>
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="startDate" />
                        </FormField>
                    </div>
                    <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                        <FormField stretch label={formatMessage(messages.endDateLabel)}>
                            <DatePicker
                                id="classForm__endDate"
                                ariaRequired
                                invalid={
                                    !!fieldsInvalid.endDate ||
                                    endDateInPast ||
                                    endDateBeforeStartDate ||
                                    endDateTooFar
                                }
                                disabled={hasClassStarted}
                                locale={locale}
                                value={dateTimeData.endDate}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.value,
                                        setData: setDateTimeData,
                                        keyPath: 'endDate',
                                    });
                                }}
                                placeholder="YYYY/MM/DD"
                                todayLabel={formatMessage(messages.todayLabel)}
                                nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                previousMonthLabel={formatMessage(messages.previousMonthLabel)}
                            ></DatePicker>
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="endDate" />
                            {endDateInPast ? (
                                <ErrorMessage>
                                    {formatMessage(messages.endDateEndDatePassed)}
                                </ErrorMessage>
                            ) : null}
                            {endDateBeforeStartDate ? (
                                <ErrorMessage>
                                    {formatMessage(messages.endDateEndBeforeStart)}
                                </ErrorMessage>
                            ) : null}
                            {endDateTooFar ? (
                                <ErrorMessage>
                                    {formatMessage(messages.endDateDurationExceeded)}
                                </ErrorMessage>
                            ) : null}
                        </FormField>
                    </div>
                </div>
                <div className="awsui-row">
                    <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                        <FormField
                            stretch
                            id="FormField__startTime"
                            controlId="classForm__startTime"
                            label={formatMessage(messages.startTimeLabel)}
                        >
                            <TimeInput
                                format="hh:mm"
                                placeholder="hh:mm"
                                controlId="classForm__startTime"
                                className="class-form__time-input"
                                aria-labelledby="FormField__startTime"
                                invalid={!!fieldsInvalid.startTime}
                                disabled={hasClassStarted}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.value,
                                        setData: setDateTimeData,
                                        keyPath: 'startTime',
                                    });
                                }}
                                ariaRequired
                                value={dateTimeData.startTime}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="startTime" />
                        </FormField>
                    </div>
                    <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                        <FormField
                            stretch
                            id="FormField__endTime"
                            controlId="classForm__endTime"
                            label={formatMessage(messages.endTimeLabel)}
                        >
                            <TimeInput
                                format="hh:mm"
                                placeholder="hh:mm"
                                controlId="classForm__endTime"
                                className="class-form__time-input"
                                aria-labelledby="FormField__endTime"
                                invalid={
                                    !!fieldsInvalid.endTime ||
                                    endTimeBeforeStartTime ||
                                    endDateInPast
                                }
                                disabled={hasClassStarted}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.value,
                                        setData: setDateTimeData,
                                        keyPath: 'endTime',
                                    });
                                }}
                                ariaRequired
                                value={dateTimeData.endTime}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="endTime" />
                            {endTimeBeforeStartTime ? (
                                <ErrorMessage>
                                    {formatMessage(messages.startEndTimeInvalid)}
                                </ErrorMessage>
                            ) : null}
                        </FormField>
                    </div>
                </div>
            </div>
        </FormSection>
    );
};

export default ClassTime;
