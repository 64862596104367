import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { getErrorMessage } from './ClassForm.messages';

export const ErrorMessage = ({ children }) => (
    <p className="class-form__error-message">{children}</p>
);

export const FieldErrors = ({ fieldsInvalid, property }) => {
    const { formatMessage } = useIntl();

    return fieldsInvalid[property] ? (
        <Fragment>
            {fieldsInvalid[property].map(
                ({ code }, i) =>
                    code && (
                        <ErrorMessage data-testid={code} key={`${code}-${i}`}>
                            {formatMessage(getErrorMessage(property, code))}
                        </ErrorMessage>
                    )
            )}
        </Fragment>
    ) : null;
};
