import { useIntl } from 'react-intl';
import { Button, Modal } from '@amzn/awsui-components-react';

import { messages } from 'components/classForm/ClassForm.messages';
import './CapacityChangeModal.css';

export const NEED_TO_CLEAR = 'needToClear';
export const CLEARED = 'cleared';
export const CANCELLED = 'cancelled';
export const NO_NEED = 'noNeed';
export const LICENSE_ERROR = 'licenseError';

const getMessages = status => {
    switch (status) {
        case LICENSE_ERROR:
            return {
                headerText: messages.rosterResizeIncreaseErrorHeader,
                mainContent: messages.rosterResizeIncreaseErrorContent,
                primaryBtnLabel: messages.doneButtonText,
            };
        default:
            return {
                headerText: messages.rosterResizeIncreaseHeader,
                mainContent: messages.rosterResizeIncreaseContent,
                primaryBtnLabel: messages.doneButtonText,
            };
    }
};

const CapacityChangeModal = ({ statusSet, status, originalSize, newSize }) => {
    const { formatMessage } = useIntl();
    const visibleStatuses = [NEED_TO_CLEAR, LICENSE_ERROR];
    if (!visibleStatuses.includes(status)) {
        return null;
    }

    const { headerText, mainContent, primaryBtnLabel } = getMessages(status);
    const additionalNum = newSize - originalSize;
    return (
        <Modal
            onDismiss={() => statusSet(CANCELLED)}
            visible
            header={headerText && formatMessage(headerText)}
            closeLabel={formatMessage(messages.closeModalButtonLabel)}
            footer={
                <div className="capacity-modal__footer">
                    <span className="awsui-util-f-r">
                        <Button
                            onClick={e => {
                                statusSet(status === NEED_TO_CLEAR ? CLEARED : CANCELLED);
                            }}
                            variant="primary"
                        >
                            {formatMessage(primaryBtnLabel)}
                        </Button>
                    </span>
                    <span className="awsui-util-f-r">
                        <Button onClick={() => statusSet(CANCELLED)} variant="normal">
                            <span data-testid="capacity-modal__cancel-btn">
                                {formatMessage(messages.cancelButtonText)}
                            </span>
                        </Button>
                    </span>
                </div>
            }
        >
            {formatMessage(mainContent, { additionalNum })}
        </Modal>
    );
};

export default CapacityChangeModal;
