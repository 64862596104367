import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Form, Button } from '@amzn/awsui-components-react';
import { pick } from 'lodash';

import { paths } from 'utils/paths';
import { useUserInfo } from 'utils/userInfo';
import { getEpochDate, timeHasPassed } from 'utils/timestamp-utils';

import { useProvider } from 'data/ProviderContext';
import { messages } from './ClassForm.messages';
import {
    LOCATION_TYPES,
    handleFormValueChange,
    transformIncomingDataToFormData,
    transformIncomingDataToDateTimeData,
    initialFieldsInvalidState,
    validateData,
    prepareFormData,
    putUserEmailFirstInArray,
} from './ClassForm.utils';
import './ClassForm.css';
import InstructorAssign from '../instructorAssign/InstructorAssign';
import CourseInformation from './courseInformation/CourseInformation';
import StudentCode from './studentCode/StudentCode';
import ClassTime from './classTime/ClassTime';
import ClassLocation from './classLocation/ClassLocation';
import CapacityChangeModal, * as STATUSES from '../capacityChangeModal/CapacityChangeModal';
import { ExpiredClassModal } from 'components';

const ClassForm = ({ onSubmit, onError, classroomId, data }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const userInfo = useUserInfo();
    const providerFromStorage = useProvider();

    const [formProcessing, setFormProcessing] = useState(false);
    const [classData, setClassData] = useState({
        courseId: '',
        courseVersionId: '',
        langLocale: '',
        locationType: LOCATION_TYPES.virtual,
        classCapacity: '1',
        virtualUrl: '',
        timezone: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        instructors: [],
        ...transformIncomingDataToFormData(data),
    });
    const [classCapacityChangeStatus, classCapacityChangeStatusSet] = useState(STATUSES.NO_NEED);
    const isNewClass = classroomId === undefined;

    let providerName = data?.trainingProviderName; // provider name when editing class
    let providerType = data?.trainingProviderType; // provider type when editing class
    if (isNewClass) {
        providerName = providerFromStorage?.name;
        providerType = providerFromStorage?.type;
    }
    // set default valid state of all fields to true
    const [fieldsInvalid, setFieldsInvalid] = useState(initialFieldsInvalidState);
    const [dateTimeData, setDateTimeData] = useState(transformIncomingDataToDateTimeData(data));
    const [dateTimesAreValid, setDateTimesAreValid] = useState(true);

    const hasClassExpired = !isNewClass && timeHasPassed(data.endsOn);
    const hasClassStarted = isNewClass ? false : timeHasPassed(data.startsOn);
    const rosterSizeDifference = parseInt(classData?.classCapacity) - (data?.classCapacity ?? 0);

    useEffect(() => {
        if (userInfo.isLoading || !userInfo.email) return;

        if (classData.instructors.length === 0) {
            const initialValue = !userInfo.userIsTrainingCoordinator ? userInfo.email : '';
            handleFormValueChange({
                value: [initialValue],
                setData: setClassData,
                keyPath: 'instructors',
            });
        } else if (
            !userInfo.userIsTrainingCoordinator &&
            classData.instructors[0] !== userInfo.email
        ) {
            const initialValue = putUserEmailFirstInArray(classData.instructors, userInfo.email);
            handleFormValueChange({
                value: initialValue,
                setData: setClassData,
                keyPath: 'instructors',
            });
        }
    }, [userInfo, classData.instructors]);

    useEffect(() => {
        if (classCapacityChangeStatus === STATUSES.CLEARED) {
            handleFormSubmit(new Event('submit', { cancelable: true }));
        } else if (classCapacityChangeStatus === STATUSES.CANCELLED) {
            setFormProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classCapacityChangeStatus]);

    const handleFormSubmit = e => {
        e.preventDefault();
        // Disable form action buttons to provide user feedback
        setFormProcessing(true);
        setFieldsInvalid(initialFieldsInvalidState);

        // Skip instructor validation if no instructors were added by a Training Coordinator
        if (
            userInfo.userIsTrainingCoordinator &&
            classData.instructors.every(email => email === '')
        ) {
            classData.instructors = [];
        }

        const { dataValidity, allValid } = validateData(
            { ...classData, ...dateTimeData },
            userInfo,
            rosterSizeDifference
        );

        // if any values are false
        if (!allValid || !dateTimesAreValid) {
            setFieldsInvalid(dataValidity);
            setFormProcessing(false);
            return;
        }

        // show warning modal if trying to increase roster size
        const needToStopForCapacityIncreaseModal =
            !isNewClass &&
            rosterSizeDifference > 0 &&
            classCapacityChangeStatus !== STATUSES.CLEARED;

        if (needToStopForCapacityIncreaseModal && providerFromStorage?.type === 'ATP') {
            classCapacityChangeStatusSet(STATUSES.NEED_TO_CLEAR);
            setFormProcessing(false);
            return;
        }

        const mutationVars = prepareFormData({
            ...classData,
            ...getEpochDate(dateTimeData, classData.timezone),
            classroomId,
            providerArn: data?.providerArn || providerFromStorage?.arn,
        });

        return onSubmit(mutationVars)
            .then(data => {
                setFormProcessing(false);
                if (data) {
                    history.push(paths.classDetailPage(data.classroomId));
                }
            })
            .catch(err => {
                setFormProcessing(false);
                try {
                    const { errors } = JSON.parse(err);
                    const invalidFields = errors.reduce(
                        (acc, { property, errorCodes }) => ({
                            ...acc,
                            [property]: errorCodes.map(({ code, values }) => ({
                                values,
                                code: code.replace('PropertyError:', ''),
                            })),
                        }),
                        {}
                    );
                    if (
                        Array.isArray(invalidFields?.classCapacity) &&
                        invalidFields?.classCapacity.find(({ code }) => code === 'IncreaseInvalid')
                    ) {
                        classCapacityChangeStatusSet(STATUSES.LICENSE_ERROR);
                    }

                    setFieldsInvalid(invalidFields);
                } catch (anotherError) {
                    // the original error was not because of backend validation, escalate
                    console.log('original error', err);
                    // weird behavior from graphql where the caught error is undefined
                    onError(err || 'graphql error');
                }
            });
    };

    const FormActions = () => (
        <Fragment>
            {!isNewClass ? (
                <Button
                    variant="link"
                    disabled={formProcessing}
                    onClick={() => history.push(paths.classDetailPage(classroomId))}
                >
                    {formatMessage(messages.cancelButtonText)}
                </Button>
            ) : null}
            <Button
                variant="primary"
                loading={formProcessing}
                disabled={hasClassExpired}
                label={formatMessage(messages.saveButtonLabel)}
                onClick={handleFormSubmit}
            >
                {formatMessage(messages.saveButtonText)}
            </Button>
        </Fragment>
    );

    return (
        <div>
            {providerName ? (
                <h1 className="awsui-text-large awsui-util-pb-l">{providerName}</h1>
            ) : null}
            <div className="awsui-util-action-stripe-large">
                <div className="awsui-util-action-stripe-title">
                    <h2>
                        {isNewClass
                            ? formatMessage(messages.addPageTitle)
                            : formatMessage(messages.editPageTitle)}
                    </h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                    <FormActions />
                </div>
            </div>
            <Form actions={<FormActions />}>
                <CourseInformation
                    handleFormValueChange={handleFormValueChange}
                    isNewClass={isNewClass}
                    fieldsInvalid={fieldsInvalid}
                    courseId={classData.courseId}
                    courseVersionId={classData.courseVersionId}
                    langLocale={classData.langLocale}
                    setClassData={setClassData}
                />

                <InstructorAssign
                    providerName={providerName}
                    providerType={providerType}
                    instructors={classData.instructors}
                    fieldsInvalid={fieldsInvalid}
                    instructorsSet={newValue => {
                        handleFormValueChange({
                            value: newValue,
                            setData: setClassData,
                            keyPath: 'instructors',
                        });
                    }}
                />

                <StudentCode
                    fieldsInvalid={fieldsInvalid}
                    hasClassStarted={hasClassStarted}
                    classCapacity={classData.classCapacity}
                    data={data}
                    setClassData={setClassData}
                />

                <ClassTime
                    fieldsInvalid={fieldsInvalid}
                    timezone={classData.timezone}
                    setClassData={setClassData}
                    dateTimeData={dateTimeData}
                    setDateTimeData={setDateTimeData}
                    setDateTimesAreValid={setDateTimesAreValid}
                    hasClassStarted={hasClassStarted}
                />

                <ClassLocation
                    {...pick(classData, [
                        'locationType',
                        'virtualUrl',
                        'addressLine1',
                        'addressLine2',
                        'city',
                        'state',
                        'postalCode',
                        'country',
                    ])}
                    setClassData={setClassData}
                    fieldsInvalid={fieldsInvalid}
                    handleFormSubmit={handleFormSubmit}
                />

                <CapacityChangeModal
                    status={classCapacityChangeStatus}
                    statusSet={classCapacityChangeStatusSet}
                    newSize={parseInt(classData.classCapacity)}
                    originalSize={data?.classCapacity}
                />

                <ExpiredClassModal
                    initiallyExpired={hasClassExpired}
                    expiredFromBackend={(fieldsInvalid.endsOn || []).includes('EndDatePassed')}
                />
            </Form>
        </div>
    );
};

export default ClassForm;
