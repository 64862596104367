import React from 'react';
import { useIntl } from 'react-intl';
import { ColumnLayout, FormField, Input, FormSection } from '@amzn/awsui-components-react';

import { messages } from '../ClassForm.messages';
import { handleFormValueChange, MAX_CLASS_CAPACITY } from '../ClassForm.utils';
import { FieldErrors } from '../FieldErrors';
import '../ClassForm.css';

const StudentCode = ({ fieldsInvalid, classCapacity, data, hasClassStarted, setClassData }) => {
    const { formatMessage } = useIntl();
    return (
        <FormSection
            header={formatMessage(messages.codeInfoHeader)}
            data-testid="class-capacity__form-section"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <p>{formatMessage(messages.multiUseCodeLabel)}</p>
                    <FormField
                        stretch
                        description={formatMessage(messages.numberOfStudentsHint)}
                        label={formatMessage(messages.numberOfStudentsLabel)}
                    >
                        <Input
                            ariaRequired
                            invalid={!!fieldsInvalid.classCapacity}
                            disabled={!!hasClassStarted}
                            value={classCapacity}
                            type="number"
                            onChange={e => {
                                handleFormValueChange({
                                    value: Math.min(
                                        MAX_CLASS_CAPACITY,
                                        Math.max(e.detail.value, 1)
                                    ).toString(),
                                    setData: setClassData,
                                    keyPath: 'classCapacity',
                                });
                                const accessCode =
                                    e.detail.value !== data?.classCapacity
                                        ? data?.accessCodes[0]?.accessCode
                                        : '';
                                handleFormValueChange({
                                    value: accessCode,
                                    keyPath: 'accessCode',
                                    setData: setClassData,
                                });
                            }}
                        />
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="classCapacity" />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default StudentCode;
