import { useEffect, useContext, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { getClassroom } from 'graphql/queries';
import { getClassroomWithInstructors } from 'graphql/custom';
import { ClassroomContext } from 'data/ClassroomContext';
import { useAcceptInvitations } from 'utils/useAcceptInvitations';
import { executeRequest } from 'modules/api';
import { useProvider } from './ProviderContext';

const DELAYS = [500, 1000, 1500, 2000];

export const useClassroomData = (
    classroomId,
    { withConsistencyRetry = false, withInstructors = false } = {}
) => {
    const setError = useErrorHandler();
    const { classData, loadInitialState } = useContext(ClassroomContext);
    const provider = useProvider()
    const acceptedInvitations = useAcceptInvitations();
    const [retryNum, retryNumSet] = useState(withConsistencyRetry ? 0 : DELAYS.length);
    const [timeoutId, timeoutIdSet] = useState();
    const operation = withInstructors ? getClassroomWithInstructors : getClassroom;

    useEffect(() => {
        if (!classroomId || !provider) return;
        executeRequest({
            operation,
            params: { classroomId, providerArn: provider.arn },
        })
            .then(async ({ getClassroom: payload, listClassroomRelationships }) => {
                payload.classroom.trainingProviderName = provider.name;
                payload.classroom.trainingProviderType = provider.type;
                if (withInstructors && listClassroomRelationships?.emails) {
                    payload.classroom.instructors = listClassroomRelationships?.emails;
                }
                loadInitialState(payload);
            })
            .catch(err => {
                if (retryNum < DELAYS.length) {
                    if (err.statusCode === 403) {
                        if (acceptedInvitations) {
                            if (acceptedInvitations.length > 0) {
                                timeoutIdSet(
                                    setTimeout(() => retryNumSet(retryNum + 1), DELAYS[retryNum])
                                );
                            } else {
                                setError(err);
                            }
                        }
                    } else if (err.statusCode >= 500) {
                        timeoutIdSet(setTimeout(() => retryNumSet(retryNum + 1), DELAYS[retryNum]));
                    } else {
                        setError(err);
                    }
                } else {
                    setError(err);
                }
            });

        return () => {
            clearInterval(timeoutId);
            timeoutIdSet(null);
        };
        // deliberately not adding timeoutId in the dependency array
        // eslint-disable-next-line
    }, [classroomId, loadInitialState, setError, acceptedInvitations, retryNum]);

    return classData;
};
