import React, { useState, useEffect } from 'react';
import { ColumnLayout, FormField, Input, Select, FormSection } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import _get from 'lodash/get';

import { getCourses, getAllCourseVersions, getCourseVersionDetails } from 'modules';
import { messages } from '../ClassForm.messages';
import {
    mapCoursesToSelectObject,
    mapCoursesVersionsToSelectObject,
    mapLocalesToSelectObject,
    usePrevious,
    handleFormValueChange,
} from '../ClassForm.utils';
import { FieldErrors } from '../FieldErrors';
import '../ClassForm.css';

const CourseInformation = ({
    isNewClass,
    fieldsInvalid,
    courseId,
    courseVersionId,
    langLocale,
    setClassData,
}) => {
    const { formatMessage } = useIntl();
    const [courses, setCourses] = useState([]);
    const [courseVersions, setCourseVersions] = useState([]);
    const [courseVersionsLoading, setCourseVersionsLoading] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState({});
    const [courseVersionDetailsLoading, courseVersionDetailsLoadingSet] = useState(false);

    // usePrevious helps to determine if there was a change between actual
    // values for courseId and courseVersionId which inform whether to reset
    // the courseVersionId and langLocale inputs respectively. This matters
    // when a form is updated with data returned from an existing class because
    // it is responding to changes and would clear out any current choice when
    // populating the form state with the provided data
    const prevCourseId = usePrevious(courseId);
    const prevCourseVersionId = usePrevious(courseVersionId);

    // get courses data
    useEffect(() => {
        getCourses().then(setCourses);
    }, []);

    // reset any selected course version id when course id changes and get
    // corresponding course versions
    useEffect(() => {
        if (prevCourseId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseVersionId',
            });
            courseVersionDetailsSet({});
        }
        if (!courseId) return;
        setCourseVersionsLoading(true);
        getAllCourseVersions(courseId)
            .then(setCourseVersions)
            .finally(() => setCourseVersionsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    // reset any selected langLocale when course version id changes and get
    // corresponding locales
    useEffect(() => {
        if (prevCourseVersionId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'langLocale',
            });
        }
        if (!courseVersionId) return;
        courseVersionDetailsLoadingSet(true);
        getCourseVersionDetails(courseVersionId)
            .then(courseVersionDetailsSet)
            .finally(() => courseVersionDetailsLoadingSet(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseVersionId]);

    return (
        <FormSection header={formatMessage(messages.courseInfoHeader)}>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField stretch label={formatMessage(messages.courseTemplateLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-course-ids"
                            invalid={!!fieldsInvalid.courseId}
                            disabled={!isNewClass}
                            selectedId={courseId}
                            label={formatMessage(messages.courseTemplatePlaceholder)}
                            options={courses
                                .map(mapCoursesToSelectObject)
                                .sort((a, b) =>
                                    a.label.toUpperCase() < b.label.toUpperCase() ? -1 : +1
                                )}
                            empty={formatMessage(messages.courseTemplateEmptyLabel)}
                            placeholder={formatMessage(messages.courseTemplatePlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={e => {
                                handleFormValueChange({
                                    value: e.detail.selectedId,
                                    setData: setClassData,
                                    keyPath: 'courseId',
                                });
                            }}
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="courseId" />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseVersionLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-course-versions"
                            invalid={!!fieldsInvalid.courseVersionId}
                            selectedId={courseVersionId}
                            label={formatMessage(messages.courseVersionPlaceholder)}
                            options={courseVersions.map(
                                mapCoursesVersionsToSelectObject(formatMessage)
                            )}
                            empty={formatMessage(
                                courseVersionsLoading
                                    ? messages.loadingField
                                    : messages.courseVersionEmptyLabel
                            )}
                            placeholder={formatMessage(messages.courseVersionPlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={e =>
                                handleFormValueChange({
                                    value: e.detail.selectedId,
                                    setData: setClassData,
                                    keyPath: 'courseVersionId',
                                })
                            }
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="courseVersionId" />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseVersionContentLabel)}>
                        <Input
                            data-testid="course-information-content-version"
                            className="class-form__semantic-version-input"
                            readonly
                            disabled
                            value={_get(courseVersionDetails, 'contentVersion', '')}
                        />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseLanguageLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-lang-locales"
                            invalid={!!fieldsInvalid.langLocale}
                            selectedId={langLocale}
                            label={formatMessage(messages.courseLanguagePlaceholder)}
                            options={_get(courseVersionDetails, 'supportedLocales', []).map(
                                mapLocalesToSelectObject
                            )}
                            empty={formatMessage(
                                courseVersionDetailsLoading
                                    ? messages.loadingField
                                    : messages.courseLanguageEmptyLabel
                            )}
                            placeholder={formatMessage(messages.courseLanguagePlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={e =>
                                handleFormValueChange({
                                    value: e.detail.selectedId,
                                    setData: setClassData,
                                    keyPath: 'langLocale',
                                })
                            }
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="langLocale" />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default CourseInformation;
